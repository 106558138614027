
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/dark.css';



flatpickr(".datepicker_flatpickr", {

});

// why do you requrie the jquery stuff ?
// is that not globally available
$(document).on('turbo:load', function() {
  $(".datepicker_flatpickr").flatpickr({
  
  });
  });

$(document).on('turbo:load', function() {
  $(".datetimepicker_flatpickr").flatpickr({
      enableTime: true,
  });
  });
